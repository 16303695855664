var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex-1 mt-1 bg-gray-100 px-4 pt-8 shadow-lg items-center"
  }, [_c('div', {
    staticClass: "bg-white w-fullrounded-lg shadow"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6 mt-5"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('button', {
    staticClass: "rounded-full flex justify-center items-center w-8 h-8 mr-3",
    class: _vm.$colors.primary,
    on: {
      "click": function click($event) {
        return _vm.RouterBack();
      }
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("chevron_left")])]), _vm.formData._id ? _c('div', {
    staticClass: "text-xl font-semibold text-green-600"
  }, [_vm._v(" " + _vm._s(_vm.$t("update_order")) + " ")]) : _c('div', {
    staticClass: "text-xl font-semibold text-green-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("new_order")) + " ")])])]), _c('div', {
    staticClass: "px-6 mt-3"
  }, [_c('div', {
    staticClass: "flex w-full space-x-2"
  }, [_c('div', {
    staticClass: "flex w-1/2 justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_vm._m(0), _c('div', {
    staticClass: "ml-5 w-full"
  }, [_c('div', {
    staticClass: "text-base mt-1 font-semibold text-gray-600 pb-10"
  }, [_vm._v(" " + _vm._s(_vm.$t("customer_information")) + " ")]), _c('div', {
    staticClass: "text-sm font-light text-gray-500"
  }), _c('div', {
    staticClass: "w-full space-y-4"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerFullName"
    }
  }, [_vm._v(_vm._s(_vm.$t("full_name")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.customer.fullName,
      expression: "formData.customer.fullName"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "customerFullName",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.customer.fullName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.customer, "fullName", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerPhone"
    }
  }, [_vm._v(_vm._s(_vm.$t("phone")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.customer.phone,
      expression: "formData.customer.phone"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "customerPhone",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.customer.phone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.customer, "phone", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "country"
    }
  }, [_vm._v(_vm._s(_vm.$t("country")))])])]), _c('p', [_c('v-select', {
    attrs: {
      "options": _vm.warehousesusers,
      "label": "name"
    },
    on: {
      "input": _vm.setSelected
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        return [_c('img', {
          staticClass: "flag-img",
          attrs: {
            "src": _vm.$f.getCountryCode(option.code)
          }
        }), _vm._v(" " + _vm._s(option.name) + " ")];
      }
    }]),
    model: {
      value: _vm.formData.customer.country,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.customer, "country", $$v);
      },
      expression: "formData.customer.country"
    }
  })], 1)]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "city"
    }
  }, [_vm._v(_vm._s(_vm.$t("city")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    attrs: {
      "options": _vm.cities
    },
    on: {
      "input": _vm.setSelectedCity
    },
    model: {
      value: _vm.formData.customer.city,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.customer, "city", $$v);
      },
      expression: "formData.customer.city"
    }
  })], 1)]), _vm.formData.customer.city && _vm.feesShipp && _vm.selectContry == 'MA' ? _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "Frais"
    }
  }, [_vm._v(_vm._s(_vm.$t("shipping_cost")) + " "), _c('sup', [_vm._v("(" + _vm._s(_vm.currentCurrency) + ")")])])])]), _c('p', [_c('input', {
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "Frais",
      "autocomplete": "false",
      "disabled": "",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.feesShipp
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerAddress"
    }
  }, [_vm._v(_vm._s(_vm.$t("adress")) + " ")])])]), _c('p', [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.customer.address,
      expression: "formData.customer.address"
    }],
    staticClass: "py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full",
    attrs: {
      "id": "customerAddress",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.customer.address
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.customer, "address", $event.target.value);
      }
    }
  })])])])])])]), _c('div', {
    staticClass: "flex w-1/2 justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
  }, [_c('div', {
    staticClass: "flex w-full mt-2"
  }, [_vm._m(1), _c('div', {
    staticClass: "ml-5 w-full"
  }, [_c('div', {
    staticClass: "text-base mt-1 font-semibold text-gray-600 pb-10"
  }, [_vm._v(" " + _vm._s(_vm.$t("order_information")) + " ")]), _c('div', {
    staticClass: "text-sm font-light text-gray-500"
  }), _c('div', {
    staticClass: "w-full space-y-4"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "source"
    }
  }, [_vm._v(_vm._s(_vm.$t("order_source")) + " ")])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.source,
      expression: "formData.source"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "source",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.source
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "source", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "sourceLink"
    }
  }, [_vm._v(_vm._s(_vm.$t("order_source_link")) + " ")])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.sourceLink,
      expression: "formData.sourceLink"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "sourceLink",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.sourceLink
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "sourceLink", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "date"
    }
  }, [_vm._v(_vm._s(_vm.$t("order_date")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('datetime-v2', {
    attrs: {
      "placeholder": "Order date ...",
      "input-class": "py-2 text-xs px-2 outline-none block h-full w-full"
    },
    model: {
      value: _vm.formData.date,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "date", $$v);
      },
      expression: "formData.date"
    }
  })], 1)]), _vm.currentUser.type != 'Seller' ? _c('div', {
    staticClass: "border hover:border-blue-400 w-full focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "date"
    }
  }, [_vm._v("Shipping date "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('datetime-v2', {
    attrs: {
      "placeholder": "Shipping date ...",
      "input-class": "py-2 text-xs px-2 outline-none block h-full w-full"
    },
    model: {
      value: _vm.formData.shippingdate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "shippingdate", $$v);
      },
      expression: "formData.shippingdate"
    }
  })], 1)]) : _vm._e(), _vm.currentUser && _vm.currentUser.type && _vm.currentUser.type != 'Seller' ? _c('div', {
    staticClass: "border seller-select h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "seller"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("seller")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    attrs: {
      "label": "fullName",
      "disabled": _vm.formData && _vm.formData._id ? true : false,
      "options": _vm.sellers
    },
    on: {
      "input": _vm.changeSeller
    },
    model: {
      value: _vm.formData.seller,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "seller", $$v);
      },
      expression: "formData.seller"
    }
  })], 1)]) : _vm._e()])])])])]), _c('div', {
    staticClass: "flex w-full"
  }, [_c('div', {
    staticClass: "flex space-x-2 w-full shadow-xs mt-1 rounded-lg"
  }, [_c('div', {
    staticClass: "flex w-full justify-between items-center h-auto p-6"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_vm._m(2), _c('div', {
    staticClass: "ml-3 w-full p-2 pb-8"
  }, [_c('div', {
    staticClass: "w-full flex text-base font-semibold text-gray-600"
  }, [_c('p', {
    staticClass: "w-1/2"
  }, [_vm._v(_vm._s(_vm.$t("products")))]), _c('div', {
    staticClass: "w-full"
  }, [_c('button', {
    staticClass: "flex text-white mx-auto max-w-sm rounded-md text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right",
    attrs: {
      "disabled": _vm.isDisabled
    },
    on: {
      "click": _vm.OpenPopUp
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("select_product")) + " ")])])]), _c('div', {
    staticClass: "mt-4 overflow-x-auto"
  }, [_vm.formData.details ? _c('table', {
    staticClass: "min-w-full leading-normal"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("name")) + " ")]), _c('th', {
    staticClass: "px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("unit_price")) + " ")]), _c('th', {
    staticClass: "px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity")) + " ")]), _c('th', {
    staticClass: "px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("total")) + " ")]), _c('th', {
    staticClass: "px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("actions")) + " ")])])]), _vm._l(_vm.formData.details, function (item, index) {
    return _c('tbody', {
      key: index
    }, [_c('tr', [_c('td', {
      staticClass: "px-6 py-2 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "text-xs leading-5 capitalize px-2 rounded-full text-gray-900"
    }, [_vm._v(" " + _vm._s(item.product.name) + " ")])]), _c('td', {
      staticClass: "px-6 py-2 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.unitPrice,
        expression: "item.unitPrice"
      }],
      staticClass: "border border-green-600 py-2 text-xs px-2 outline-none block h-full w-full",
      attrs: {
        "autocomplete": "false",
        "tabindex": "0",
        "min": "0",
        "type": "number"
      },
      domProps: {
        "value": item.unitPrice
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(item, "unitPrice", $event.target.value);
        }
      }
    })])]), _c('td', {
      staticClass: "px-6 py-2 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "flex space-x-6"
    }, [_c('button', {
      staticClass: "bg-green-600 rounded-md w-6 h-6 flex items-center focus:outline-none",
      class: _vm.$colors.textPrimary,
      on: {
        "click": function click($event) {
          return _vm.addQuantity(index);
        }
      }
    }, [_vm._m(3, true)]), _c('span', {
      staticClass: "text-gray-700 text-sm h-6 flex items-center focus:outline-none",
      class: _vm.$colors.textPrimary
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.quantity,
        expression: "item.quantity"
      }],
      staticClass: "focus:outline-none w-12 text-center",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": item.quantity
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(item, "quantity", $event.target.value);
        }
      }
    })]), _c('button', {
      staticClass: "bg-green-600 rounded-md w-6 h-6 text-white flex items-center focus:outline-none",
      class: _vm.$colors.textPrimary,
      on: {
        "click": function click($event) {
          return _vm.removeQuantity(index);
        }
      }
    }, [_vm._m(4, true)])]), _c('div', {
      staticClass: "text-xs leading-5 capitalize px-2 rounded-full text-gray-900"
    })]), _c('td', {
      staticClass: "px-6 py-2 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "text-xs leading-5 capitalize px-2 rounded-full text-gray-900"
    }, [_vm._v(" " + _vm._s(item.unitPrice * item.quantity) + " "), _c('sup', {
      staticClass: "text-black"
    }, [_vm._v("(" + _vm._s(_vm.currentCurrency) + ")")])])]), _c('td', {
      staticClass: "py-4 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "flex justify-center"
    }, [_c('button', {
      staticClass: "rounded-full flex focus:outline-none w-8 h-8 mr-3 mt-1",
      class: _vm.$colors.actionTable,
      on: {
        "click": function click($event) {
          return _vm.removeProduct(item, index);
        }
      }
    }, [_c('i', {
      staticClass: "material-icons mx-1"
    }, [_vm._v("delete")])])])])])]);
  }), _c('tbody', [_c('tr', [_c('td', {
    staticClass: "px-6 text-right py-2 whitespace-no-wrap border-gray-200",
    attrs: {
      "colspan": "4"
    }
  }, [_c('div', {
    staticClass: "text-xs leading-5 capitalize px-2 rounded-full font-bold text-gray-900"
  }, [_vm._v(" " + _vm._s(_vm.$t("total_price")) + ": " + _vm._s(_vm.TotalPrice()) + " "), _c('sup', {
    staticClass: "text-black"
  }, [_vm._v("(" + _vm._s(_vm.currentCurrency) + ")")])])])])])], 2) : _vm._e()])])])])])])]), _c('div', {
    staticClass: "mt-6 pt-3 flex justify-center p-10"
  }, [_c('button', {
    staticClass: "rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300 loidingconfirm relative",
    class: _vm.loading ? 'noselect' : '',
    on: {
      "click": _vm.save
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("save")) + " ")], 1)]), _c('vue-modal-2', {
    attrs: {
      "name": "myModal",
      "headerOptions": {
        title: _vm.$t('add_products')
      },
      "noFooter": true,
      "modalSize": "lg"
    },
    on: {
      "on-close": _vm.closePopUp
    }
  }, [_c('div', {
    staticClass: "flex flex-col w-full h-auto p-5 pt-0 mb-12"
  }, [_c('div', {
    staticClass: "flex w-full h-auto justify-center items-center"
  }, [_c('div', {
    staticClass: "flex mb-2 w-full h-auto py-2 justify-center items-center text-2xl font-bold"
  }, [_c('span', {
    staticClass: "w-screen md:w-full h-10 cursor-pointer border border-gray-200 rounded text-sm flex"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchText,
      expression: "searchText"
    }],
    staticClass: "flex-grow px-4 rounded-l-full rounded-r-full text-sm focus:outline-none",
    attrs: {
      "type": "search",
      "name": "search",
      "placeholder": "Search"
    },
    domProps: {
      "value": _vm.searchText
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.searchText = $event.target.value;
      }
    }
  }), _c('i', {
    staticClass: "material-icons text-blue-400 m-2 mr-5 text-lg w-4 h-4"
  }, [_vm._v("search")])])])]), _c('div', [_vm.products.length <= 0 ? _c('div', {
    staticClass: "py-2"
  }, [_c('span', {
    staticClass: "w-full flex justify-center text-center py-20 border-b border-gray-200 text-base"
  }, [_c('p', {
    staticClass: "font-semibold flex text-blue-500 whitespace-no-wrap"
  }, [_c('span', {}, [_vm._v(" " + _vm._s(_vm.$t("no_products_found")))])])])]) : _vm._e(), _vm._l(_vm.resultSearchUsers, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "flex w-full space-x-12 h-auto py-1 px-8 rounded border border-gray-300 mb-2",
      attrs: {
        "value": item._id
      }
    }, [_c('div', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.check,
        expression: "item.check"
      }],
      staticClass: "form-checkbox mt-6 h-5 w-5 text-blue-500 border-blue-500",
      class: 'product-' + index,
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(item.check) ? _vm._i(item.check, null) > -1 : item.check
      },
      on: {
        "change": [function ($event) {
          var $$a = item.check,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(item, "check", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(item, "check", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(item, "check", $$c);
          }
        }, function ($event) {
          return _vm.addProductsToTemp(item, index);
        }]
      }
    })]), _c('div', [_c('img', {
      staticClass: "h-16 w-16 rounded-md mx-auto",
      attrs: {
        "alt": item.name,
        "src": item.picture || 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
      }
    })]), _c('div', {
      staticClass: "col-span-2 sm:col-span-4 xl:col-span-4"
    }, [_c('h3', {
      staticClass: "text-gray-80 mt-1"
    }, [_vm._v(_vm._s(item.name))]), _c('p', {
      staticClass: "text-gray-600 text-xs"
    }, [_vm._v(" " + _vm._s(_vm.exceptDes(item.description)) + " ")]), _c('p', {
      staticClass: "text-gray-600 text-xs"
    })])]);
  })], 2)]), _c('div', {
    staticClass: "modal-footer px-5 border0-t text-right"
  }, [_c('button', {
    staticClass: "flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right",
    on: {
      "click": _vm.closePopUp
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]), _c('button', {
    staticClass: "flex mr-2 text-white mx-auto max-w-sm rounded text-center bg-blue-500 py-2 px-8 items-center focus:outline-none md:float-right",
    on: {
      "click": _vm.ConfirmOperation
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")])])])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-200"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("person")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-200"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("local_grocery_store")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-200"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-500"
  }, [_vm._v("local_mall")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "font-bold text-2xl text-white"
  }, [_c('i', {
    staticClass: "material-icons mt-2"
  }, [_vm._v("add")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "font-bold text-2xl text-white mt-1"
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("remove")])]);

}]

export { render, staticRenderFns }